import each from 'lodash/each';
import { INITIAL_STATUS, createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';

const {
  getFuelCard,
  postFuelCard,
  putFuelCard,
} = backOffice.legacyFuelCards;

export const FUEL_CARD_SCOPES = {
  fuelCard: 'fuelCard',
  newFuelCard: 'newFuelCard',
};

const fuelCards = createCustomStore(({ runAsyncFlow }) => withScopes(FUEL_CARD_SCOPES, ({
  state: {
    [FUEL_CARD_SCOPES.newFuelCard]: {
      data: null,
    },
  },
  mutations: {
    clearErrors(state) {
      each(FUEL_CARD_SCOPES, scope => {
        state[scope].error = null;
        state[scope].STATUS = { ...INITIAL_STATUS };
      });
    },
  },
  actions: {
    async getFuelCard({ commit }, fuelCardUuid) {
      await runAsyncFlow(commit, {
        request: getFuelCard,
        params: [fuelCardUuid],
        scope: FUEL_CARD_SCOPES.fuelCard,
      });
    },
    async postFuelCard({ commit }, data) {
      await runAsyncFlow(commit, {
        request: postFuelCard,
        params: [data],
        scope: FUEL_CARD_SCOPES.newFuelCard,
      });
    },
    async putFuelCard({ commit }, { fuelCardUuid, data }) {
      await runAsyncFlow(commit, {
        request: putFuelCard,
        params: [fuelCardUuid, data],
        scope: FUEL_CARD_SCOPES.newFuelCard,
      });
    },
  },
})));

export default fuelCards;
