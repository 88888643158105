var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "VehicleDamageForm d-flex flex-column flex-fill" },
    [
      _c("h1", { staticClass: "mb-2" }, [
        _vm._v(
          " " +
            _vm._s(_vm.isEditing ? "Edit damage" : "Report new damage") +
            " "
        ),
      ]),
      _c(
        "MuiValidationWrapper",
        { on: { areAllValid: (isValid) => (_vm.isFormValid = isValid) } },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column emobg-border-1 emobg-border-color-ground-light emobg-background-color-white",
            },
            [
              _c(
                "h2",
                {
                  staticClass:
                    "px-3 py-4 emobg-font-large emobg-background-color-ground-lightest",
                },
                [_vm._v(" Vehicle details ")]
              ),
              _c(
                "div",
                { staticClass: "px-3 py-4" },
                [
                  _c(
                    "PermissionLink",
                    {
                      staticClass: "d-block pb-1 emobg-font-weight-semibold",
                      attrs: {
                        to: {
                          name: _vm.fleet.vehicles.detail.details,
                          params: {
                            vehicleUuid: _vm.getValue(
                              _vm.vehicle,
                              "uuid",
                              null
                            ),
                          },
                        },
                        "link-permissions": [
                          _vm.CARSHARING_PERMISSIONS.viewCarsharingVehicles,
                        ],
                        "data-test-id": "vehicle_details-link",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.vehicle.licensePlate) + " ")]
                  ),
                  _vm._v(" " + _vm._s(_vm.vehicleName) + " "),
                ],
                1
              ),
              _c(
                "h2",
                {
                  staticClass:
                    "px-3 py-4 emobg-font-large emobg-background-color-ground-lightest",
                },
                [_vm._v(" Damage details ")]
              ),
              _c(
                "ui-alert",
                {
                  staticClass: "d-flex px-3 pb-4 pt-3",
                  attrs: {
                    color: _vm.COLORS.warning,
                    icon: _vm.ICONS.alertAloneFull,
                  },
                },
                [
                  _vm._v(
                    " Ensure that no third-party information is visible on the damage picture, such as people or personal details. "
                  ),
                ]
              ),
              _c("div", { staticClass: "row px-3" }, [
                _c("div", { staticClass: "col-lg-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      !_vm.isEditingStatus
                        ? _c(
                            "ContentCellComponent",
                            { attrs: { label: "Damage status" } },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-items-center" },
                                [
                                  _c("ui-badge", {
                                    class: {
                                      disabled: _vm.hasInvestigationInProgress,
                                    },
                                    attrs: {
                                      text: _vm.sentenceCase(_vm.inputs.status),
                                      color:
                                        _vm.DAMAGES_STATUS_COLORS[
                                          _vm.inputs.status
                                        ],
                                    },
                                  }),
                                  !_vm.hasInvestigationInProgress
                                    ? _c("ui-icon", {
                                        staticClass: "ml-1 cursor-pointer",
                                        attrs: {
                                          icon: _vm.ICONS.edit,
                                          color: _vm.GRAYSCALE.inkLight,
                                          size: _vm.ICONS_SIZES.small,
                                          "data-test-id": "edit_status-action",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.isEditingStatus = true
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            [
                              _c("MuiSelect", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      isRequired: true,
                                    },
                                    expression:
                                      "{\n                  isRequired: true,\n                }",
                                  },
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  options: _vm.map(
                                    _vm.filteredDamageStatuses,
                                    (status) => ({
                                      label: _vm.sentenceCase(status),
                                      value: status,
                                    })
                                  ),
                                  "data-test-id": "status-select",
                                  label: "Damage status",
                                  placeholder: "Select",
                                  name: "status",
                                },
                                on: { change: _vm.onStatusChange },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selected",
                                    fn: function ({ item }) {
                                      return [
                                        _c("ui-badge", {
                                          attrs: {
                                            text: item.label,
                                            color:
                                              _vm.DAMAGES_STATUS_COLORS[
                                                item.value
                                              ],
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item",
                                    fn: function ({ item }) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "w-100 d-flex align-items-center justify-content-between",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "emobg-font-weight-semibold",
                                              },
                                              [_vm._v(_vm._s(item.label))]
                                            ),
                                            _c("ui-badge", {
                                              attrs: {
                                                color:
                                                  _vm.DAMAGES_STATUS_COLORS[
                                                    item.value
                                                  ],
                                                circle: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.inputs.status,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputs, "status", $$v)
                                  },
                                  expression: "inputs.status",
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("DamageImageUploader", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.update:image",
                            value: {
                              ...(_vm.getValue(_vm.damage, "imageUrl", null)
                                ? {}
                                : { isRequired: true }),
                            },
                            expression:
                              "{\n                ...getValue(damage, 'imageUrl', null) ? {} : { isRequired: true }\n              }",
                            modifiers: { "update:image": true },
                          },
                        ],
                        attrs: {
                          image: _vm.getValue(_vm.damage, "imageUrl", null),
                        },
                        on: {
                          "update:image": (image) =>
                            (_vm.inputs.damageImage = image),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("VehicleDamageArea", {
                        attrs: { sides: _vm.vehicle.images },
                        model: {
                          value: _vm.inputs.damage,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "damage", $$v)
                          },
                          expression: "inputs.damage",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "col-lg-3" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("MuiAlgoliaSelect", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isRequired: true,
                            },
                            expression:
                              "{\n                isRequired: true,\n              }",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          title: ({ internal_name }) =>
                            _vm.upperFirst(internal_name.replace(/\_/g, " ")),
                          index: _vm.ALGOLIA_INDEXES.vehicleAreas,
                          filters: `vehicle_type: ${_vm.getValue(
                            _vm.vehicle,
                            "type",
                            ""
                          )}`,
                          label: "Vehicle area*",
                          "path-value": "internal_name",
                          placeholder: "Select",
                          name: "area",
                          "data-test-id": "area-select",
                        },
                        model: {
                          value: _vm.inputs.vehicleArea,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "vehicleArea", $$v)
                          },
                          expression: "inputs.vehicleArea",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("MuiSelect", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              isRequired: true,
                            },
                            expression:
                              "{\n                isRequired: true,\n              }",
                          },
                        ],
                        staticClass: "w-100",
                        attrs: {
                          options: _vm.map(_vm.DAMAGES_TYPES, (type) => ({
                            label: _vm.sentenceCase(type),
                            value: type,
                          })),
                          label: "Damage type*",
                          placeholder: "Select",
                          name: "type",
                          "data-test-id": "type-select",
                        },
                        model: {
                          value: _vm.inputs.damageType,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "damageType", $$v)
                          },
                          expression: "inputs.damageType",
                        },
                      }),
                      _vm.isNotExteriorDamageType
                        ? _c(
                            "span",
                            { staticClass: "d-block emobg-font-x-small m-1" },
                            [
                              _vm._v(
                                " Damage picture won't be shown to app user. "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "d-block emobg-font-weight-semibold mb-1",
                        },
                        [
                          _vm._v(
                            " Description" +
                              _vm._s(_vm.isRejectedStatus ? "*" : "") +
                              " "
                          ),
                        ]
                      ),
                      _c("MuiTextarea", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              ...(_vm.isRejectedStatus
                                ? {
                                    isRequired: {
                                      message:
                                        "Please provide the reason for rejecting the damage.",
                                    },
                                  }
                                : {}),
                              isMinLength: {
                                message: "We need at least 6 characters",
                                length: 6,
                              },
                            },
                            expression:
                              "{\n                ...(isRejectedStatus) ? { isRequired: { message: 'Please provide the reason for rejecting the damage.' } } : {},\n                isMinLength: {\n                  message: 'We need at least 6 characters',\n                  length: 6,\n                }\n              }",
                          },
                        ],
                        ref: "descriptionField",
                        attrs: {
                          placeholder: "Enter",
                          "data-test-id": "description-textarea",
                          name: "description",
                          rows: "16",
                        },
                        model: {
                          value: _vm.inputs.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputs, "description", $$v)
                          },
                          expression: "inputs.description",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "mb-4 px-3" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-fill justify-content-between align-items-center",
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "d-block align-self-end emobg-font-default emobg-font-weight-semibold",
                        },
                        [_vm._v(" Attachments ")]
                      ),
                      _c(
                        "DragFileComponent",
                        {
                          ref: "fileUploader",
                          attrs: {
                            "existing-files": _vm.currentAttachments,
                            "accepted-formats": `${_vm.FILE_TYPES.image},${_vm.FILE_TYPES.pdf}`,
                            hidden: "",
                            multiple: "",
                          },
                          on: {
                            existingFileRemoved: (files) =>
                              (_vm.currentAttachments = files),
                          },
                          model: {
                            value: _vm.attachments,
                            callback: function ($$v) {
                              _vm.attachments = $$v
                            },
                            expression: "attachments",
                          },
                        },
                        [
                          _c(
                            "ui-button",
                            { attrs: { face: _vm.FACES.outline } },
                            [_vm._v(" Upload attachment ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", {
                    staticClass:
                      "emobg-border-color-ground-light emobg-border-top-1 mt-2 mb-3",
                  }),
                  _c("TableComponent", {
                    attrs: {
                      schema: _vm.attachmentsSchema,
                      data: _vm.attachmentsList,
                      "row-actions": _vm.attachmentsRowActions,
                      "empty-label": "No attachments yet",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "px-3 py-4" }, [
                _c("h2", { staticClass: "emobg-font-default" }, [
                  _vm._v(" Reporting source "),
                ]),
                _c("div", {
                  staticClass:
                    "emobg-border-color-ground-light emobg-border-top-1 mt-2 mb-4",
                }),
                _c("div", { staticClass: "w-100 row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-4" },
                    [
                      _c(
                        "ContentCellComponent",
                        { attrs: { label: "Agent" } },
                        [
                          _c(
                            "RouterLink",
                            {
                              staticClass:
                                "cursor-pointer emobg-color-primary emobg-font-weight-semibold text-decoration-none",
                              attrs: {
                                to: {
                                  name: _vm.crm.users.detail.index,
                                  params: {
                                    userUuid: _vm.getValue(
                                      _vm.originatorUser,
                                      "uuid",
                                      null
                                    ),
                                  },
                                },
                                "data-test-id": "agent-link",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    `${_vm.getValue(
                                      _vm.originatorUser,
                                      "firstName",
                                      ""
                                    )} ${_vm.getValue(
                                      _vm.originatorUser,
                                      "lastName",
                                      ""
                                    )}`
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-4 mb-3" },
                    [
                      _vm.isEditing
                        ? _c(
                            "ContentCellComponent",
                            { attrs: { label: "Reporting user" } },
                            [
                              _vm.reportingUser
                                ? _c(
                                    "RouterLink",
                                    {
                                      staticClass:
                                        "cursor-pointer emobg-color-primary emobg-font-weight-semibold text-decoration-none",
                                      attrs: {
                                        to: {
                                          name: _vm.crm.users.detail.index,
                                          params: {
                                            userUuid: _vm.getValue(
                                              _vm.reportingUser,
                                              "uuid",
                                              null
                                            ),
                                          },
                                        },
                                        "data-test-id": "reporting_user-link",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${_vm.getValue(
                                              _vm.reportingUser,
                                              "firstName",
                                              ""
                                            )} ${_vm.getValue(
                                              _vm.reportingUser,
                                              "lastName",
                                              ""
                                            )} - ${_vm.getValue(
                                              _vm.reportingUser,
                                              "email",
                                              ""
                                            )}`
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.FALLBACK_MESSAGE.dash) +
                                        " "
                                    ),
                                  ]),
                            ],
                            1
                          )
                        : _c("MuiAlgoliaSelect", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n                isRequired: true,\n              }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              title: ({ first_name, last_name, email }) =>
                                `${first_name} ${last_name} - ${email}`,
                              index: _vm.ALGOLIA_INDEXES.users,
                              filters: `cs_operator_uuid: ${_vm.activeOperatorUuid} OR employee.company.cs_operator_uuid: ${_vm.activeOperatorUuid}`,
                              label: "Reporting user*",
                              "path-value": "uuid",
                              placeholder: "Select",
                              name: "reportingUser",
                              "data-test-id": "reporting_user-select",
                            },
                            model: {
                              value: _vm.inputs.reportingUserUuid,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "reportingUserUuid", $$v)
                              },
                              expression: "inputs.reportingUserUuid",
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-md-6 col-lg-4 mb-3" },
                    [
                      _vm.isEditing
                        ? _c("ContentCellComponent", {
                            attrs: {
                              value: _vm.damage
                                ? _vm.reformatDateTime(
                                    _vm.getValue(
                                      _vm.damage,
                                      "reportingDate",
                                      null
                                    ),
                                    _vm.DATE_FORMAT.defaultExtended,
                                    _vm.operatorTimezone
                                  )
                                : null,
                              label: "Reporting date",
                            },
                          })
                        : _c("MuiDatePicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                  isValidDate:
                                    _vm.DATE_INPUT_VALIDATIONS.validDate,
                                },
                                expression:
                                  "{\n                isRequired: true,\n                isValidDate: DATE_INPUT_VALIDATIONS.validDate,\n              }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              "date-format-key":
                                _vm.DATE_FORMAT_KEYS.defaultExtended,
                              "valid-date-range":
                                _vm.REPORTING_DATE_VALID_RANGE,
                              immediate: "",
                              "data-test-id": "reporting_date-select",
                              label: "Reporting date*",
                              name: "reportingDate",
                            },
                            model: {
                              value: _vm.inputs.reportingDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "reportingDate", $$v)
                              },
                              expression: "inputs.reportingDate",
                            },
                          }),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm.isEditing
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column emobg-border-1 emobg-border-color-ground-light emobg-background-color-white mt-5",
                },
                [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "px-3 py-4 emobg-font-large emobg-background-color-ground-lightest",
                    },
                    [_vm._v(" Responsibility details ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "px-3 py-4" },
                    [
                      !_vm.hasInvestigationClosed
                        ? _c(
                            "ui-alert",
                            {
                              staticClass: "d-flex mb-3",
                              attrs: {
                                icon: _vm.canStartInvestigation
                                  ? _vm.ICONS.bold.check
                                  : _vm.ICONS.infoFull,
                                color: _vm.canStartInvestigation
                                  ? _vm.COLORS.success
                                  : _vm.COLORS.primary,
                              },
                            },
                            [
                              _vm.canStartInvestigation
                                ? [
                                    _vm._v(" Now you can "),
                                    _c(
                                      "strong",
                                      { staticClass: "emobg-font-weight-bold" },
                                      [_vm._v("Start the investigation.")]
                                    ),
                                  ]
                                : _vm.hasInvestigationInProgress
                                ? [
                                    _vm._v(" Investigation is "),
                                    _c(
                                      "strong",
                                      { staticClass: "emobg-font-weight-bold" },
                                      [_vm._v("In progress")]
                                    ),
                                    _vm._v(
                                      ". Damage status cannot be changed until investigation is closed. "
                                    ),
                                  ]
                                : [
                                    _vm._v(" To Start investigation, first "),
                                    _c(
                                      "strong",
                                      { staticClass: "emobg-font-weight-bold" },
                                      [_vm._v("save")]
                                    ),
                                    _vm._v(
                                      " the changes and make sure damage status is "
                                    ),
                                    _c(
                                      "strong",
                                      { staticClass: "emobg-font-weight-bold" },
                                      [_vm._v("Not Repaired.")]
                                    ),
                                  ],
                            ],
                            2
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex flex-fill justify-content-between align-items-center",
                        },
                        [
                          _c(
                            "h2",
                            {
                              staticClass: "emobg-font-default align-self-end",
                            },
                            [_vm._v(" Investigation resolution ")]
                          ),
                          _c(
                            "ui-button",
                            {
                              attrs: {
                                disabled:
                                  !_vm.canStartInvestigation ||
                                  _vm.newInvestigationStatus.LOADING,
                                loading: _vm.newInvestigationStatus.LOADING,
                              },
                              on: { clickbutton: _vm.startInvestigation },
                            },
                            [_vm._v(" Start investigation ")]
                          ),
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass:
                          "emobg-border-color-ground-light emobg-border-top-1 my-2",
                      }),
                      _c("p", { staticClass: "emobg-color-ink-light mb-4" }, [
                        _vm._v(
                          " Start an investigation to identify the user responsible for the damage "
                        ),
                      ]),
                      _c("InvestigationTableComponent", {
                        staticClass: "mb-6",
                        attrs: {
                          investigation: _vm.investigation,
                          "vehicle-uuid": _vm.vehicleUuid,
                          "damage-uuid": _vm.damageUuid,
                        },
                      }),
                      _c("h2", { staticClass: "emobg-font-default" }, [
                        _vm._v(" Responsibility "),
                      ]),
                      _c("div", {
                        staticClass:
                          "emobg-border-color-ground-light emobg-border-top-1 my-2",
                      }),
                      _c("p", { staticClass: "emobg-color-ink-light mb-4" }, [
                        _vm._v(
                          " This section is filled automatically based on the Investigation resolution. "
                        ),
                      ]),
                      _c("div", { staticClass: "w-100 row" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-lg-4" },
                          [
                            _c(
                              "ContentCellComponent",
                              { attrs: { label: "Responsible user" } },
                              [
                                _vm.responsibleUser
                                  ? _c(
                                      "RouterLink",
                                      {
                                        staticClass:
                                          "cursor-pointer emobg-color-primary emobg-font-weight-semibold text-decoration-none",
                                        attrs: {
                                          to: {
                                            name: _vm.crm.users.detail.index,
                                            params: {
                                              userUuid: _vm.getValue(
                                                _vm.responsibleUser,
                                                "uuid",
                                                null
                                              ),
                                            },
                                          },
                                          "data-test-id": "responsible-link",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              `${_vm.getValue(
                                                _vm.responsibleUser,
                                                "firstName",
                                                ""
                                              )} ${_vm.getValue(
                                                _vm.responsibleUser,
                                                "lastName",
                                                ""
                                              )}`
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-lg-4" },
                          [
                            _c(
                              "ContentCellComponent",
                              { attrs: { label: "Booking ID" } },
                              [
                                _vm.responsibleBooking
                                  ? _c(
                                      "RouterLink",
                                      {
                                        staticClass:
                                          "cursor-pointer emobg-color-primary emobg-font-weight-semibold text-decoration-none",
                                        attrs: {
                                          to: {
                                            name: _vm.carsharingRoutes.bookings
                                              .detail.index,
                                            params: {
                                              bookingUuid: _vm.getValue(
                                                _vm.responsibleBooking,
                                                "uuid",
                                                null
                                              ),
                                            },
                                          },
                                          "data-test-id": "booking-link",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              `#${_vm.getValue(
                                                _vm.responsibleBooking,
                                                "id",
                                                ""
                                              )}`
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6 col-lg-4" },
                          [
                            _c("MuiAlgoliaSelect", {
                              staticClass: "w-100",
                              attrs: {
                                disabled: !_vm.inputs.responsibleUserUuid,
                                title: ({ serie, number, date_ts }) =>
                                  `${serie} ${number} - ${_vm
                                    .moment(date_ts, "X")
                                    .format(_vm.DATE_FORMAT.date)}`,
                                filters: `cs_operator_fk: ${_vm.activeOperatorId} AND user_uuid: ${_vm.inputs.responsibleUserUuid}`,
                                index: _vm.ALGOLIA_INDEXES.invoices,
                                label: "Invoice number",
                                "path-value": "uuid",
                                placeholder: "Select",
                                name: "invoice",
                                "data-test-id": "invoice-select",
                              },
                              model: {
                                value: _vm.inputs.invoiceUuid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "invoiceUuid", $$v)
                                },
                                expression: "inputs.invoiceUuid",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "BottomActions d-flex p-3 emobg-border-1 emobg-border-color-ground-light position-sticky",
        },
        [
          _c(
            "ui-button",
            {
              staticClass: "mr-2",
              attrs: {
                color: _vm.GRAYSCALE.inkLight,
                face: _vm.FACES.text,
                "data-test-id": "close-button",
              },
              on: { clickbutton: _vm.goToVehicleDamagesList },
            },
            [_vm._v(" Close ")]
          ),
          _c(
            "ui-button",
            {
              attrs: {
                disabled:
                  !_vm.isFormValid ||
                  !_vm.isDamagePointFilled ||
                  _vm.hasSameValues,
                loading: _vm.newDamageStatus.LOADING,
                "data-test-id": "save-button",
              },
              on: { clickbutton: _vm.saveDamageOrShowGuardModal },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.isEditing ? "Save" : "Report new damage") + " "
              ),
            ]
          ),
        ],
        1
      ),
      _vm.isPreviewModalOpen
        ? _c("AttachmentPreviewModal", {
            attrs: {
              attachments: _vm.attachmentsForPreview,
              "start-index": _vm.startIndex,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteAttachmentOpen
        ? _c("DeleteAttachmentModal", {
            attrs: {
              attachment: _vm.attachmentToDelete,
              callback: _vm.onDeleteAttachment,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isLegalCompliantGuardModalOpen
        ? _c("LegalCompliantGuardModal", {
            attrs: {
              image:
                _vm.inputs.damageImage ||
                _vm.getValue(_vm.damage, "imageUrl", null),
              callback: _vm.saveDamage,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isLeaveModalOpen
        ? _c("LeaveWithoutSavingModal", {
            attrs: { "data-test-id": "leave_modal" },
            on: {
              "on:leave": _vm.onLeaveForm,
              closeModal: function ($event) {
                _vm.isLeaveModalOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }